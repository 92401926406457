.card-header {
  display: block;
  height: auto;
  background-color: inherit;
}
.card-header.no-border {
  border-bottom: 0;
}
.card-header .info-card-header {
  display: flex;
  justify-content: space-between;
}
.card-header .info-card-header .card-title {
  max-width: calc(100% - 24px);
  margin: 0;
  margin-bottom: 0;
  line-height: 1.5rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.card-header .info-card-header .card-tooltip {
  align-self: flex-end;
}

.bg-img {
  position: relative;
  right: 80px;
  bottom: 17px;
  width: 0;
  opacity: 0.05;
}
.bg-img img {
  height: 100px;
}

.card-block .info-card-block {
  display: flex;
}
.card-block .info-card-block .card-icon {
  display: flex;
  width: 64px;
  align-items: center;
  text-align: center;
}
.card-block .info-card-block .card-icon img {
  width: 64px;
}
.card-block .info-card-block .card-description-wrapper {
  display: grid;
  width: 100%;
  min-height: 7lh;
  align-items: center;
}
.card-block .info-card-block .card-description-wrapper .card-description {
  padding-left: 0.6rem;
}

.card.clickable a {
  color: var(--cds-global-typography-color-400, #333);
  text-decoration: none;
}

.footer-tags {
  text-align: right;
}
.footer-tags span {
  margin-bottom: 0.6em;
}

.label.label-info-secondary {
  border-color: var(--cds-alias-status-neutral-shade, #00567a);
  background: var(--cds-alias-status-neutral-tint, #e3f5fc);
}

.kubeapps-card-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow-x: auto;
}
.kubeapps-card-footer-col1 {
  margin: 0 0.6rem;
}
.kubeapps-card-footer-col2 {
  margin: 0 0.5rem 0 0;
}
