.cds-textarea-fix {
  font: caption;
}

.reduced-margin-top {
  margin-top: -0.5em;
}

/* stylelint-disable-next-line selector-class-pattern */
.ace-solarized-dark .ace_comment {
  font-style: normal;
}
