.app-repo-list-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.app-repo-list-header .refresh-all-button {
  display: inline;
  margin: 0 0 0 0.6rem;
}

.page-content {
  margin: 0 1.2rem;
}
