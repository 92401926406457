.tooltip-wrapper button {
  color: var(--clr-datagrid-font-color);
}

#access-urls-title {
  min-width: 15rem;
}

.multiline-tooltip {
  max-width: 25%;
}
