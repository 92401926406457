.info-icon {
  display: grid;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-left: 0.4rem;
}

/* stylelint-disable-next-line selector-type-no-unknown */
.is-success cds-icon {
  --color: var(--cds-alias-status-success-tint);
}

.small-tooltip {
  font-size: 75% !important;
}
