.breakable {
  word-break: break-word;
}

.table-control {
  max-width: 98%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.table-button {
  margin-right: "-0.75em";
  margin-left: "-0.75em";
}
