.confirmation-modal {
  overflow-y: hidden;
  text-align: center;
}
.confirmation-modal span {
  color: var(--cds-global-typography-color-400, #333);
}
.confirmation-modal-buttons {
  margin-top: 0.6rem;
}
.confirmation-modal-buttons cds-button {
  margin: 0 0.6rem;
}
